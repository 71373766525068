// extracted by mini-css-extract-plugin
export var cantBuyMessage = "product-module--cant-buy-message--17ZCi";
export var description = "product-module--description--uUFwe";
export var faq = "product-module--faq--5VTcT";
export var faqButton = "product-module--faq-button--+pcKa";
export var gallery = "product-module--gallery--SOFTV";
export var metamorphosis = "product-module--metamorphosis--GhVcH";
export var nextArticles = "product-module--next-articles--C9YcN";
export var opinions = "product-module--opinions--tKpWT";
export var pill = "product-module--pill--FCYHP";
export var pillSwitcher = "product-module--pill-switcher--s9c3j";
export var pillsContainer = "product-module--pillsContainer--uNiJc";
export var pricing = "product-module--pricing--cqzLr";
export var pricingContentBox = "product-module--pricing-content-box--f2EBL";
export var productOptions = "product-module--product-options--lgaiF";
export var products = "product-module--products--r0+ed";
export var productsList = "product-module--products-list--no0bQ";
export var reviews = "product-module--reviews--4L5Kg";
export var seeMoreButton = "product-module--see-more-button--FP3z+";
export var shortDescription = "product-module--short-description--Qk-ws";
export var subscriptionButton = "product-module--subscription-button--j0oMF";
export var subscriptionSp = "product-module--subscription-sp--ePS8X";
export var title = "product-module--title--5QSu3";
export var wrapper = "product-module--wrapper--VThXy";